<template>
    <div>
        <CompTable title="一级分类管理" :formComponent="create" :columns="columns" :table-api="`/gateway/apps/o2o/api/terminal/category/queryByNoPage?terminalGroupId=${$core.getUrlParam('t')}`" editKey="id" :delete-api="v => `/gateway/apps/o2o/api/terminal/category/delete?id=${v.id}`">
            <template v-slot:search="data">
                <Input v-model.trim="data.search.name" placeholder="请输入分类名称" style="width: 160px"></Input>
            </template>
        </CompTable>
    </div>
</template>

<script>
import CompTable from "../../components/comp-table.vue"
import Request from "../../utils/request"

import Create from "./create.vue"

export default {
    components: { CompTable, Create },

    data() {
        return {
            create: Create,

            columns: [
                {
                    title: "一级分类名称",
                    key: "name",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "图标",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        return params.row.icon
                            ? h("img", {
                                  style: {
                                      width: "30px",
                                      height: "30px",
                                  },
                                  attrs: {
                                      src: params.row.icon,
                                  },
                              })
                            : h("p", null, "-")
                    },
                },
                {
                    title: "排序",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("Input", {
                            props: {
                                value: params.row.sort,
                            },
                            attrs: {
                                type: "number",
                                number: "true",
                            },
                            style: {
                                width: "100px",
                            },
                            on: {
                                "on-enter": evt => {
                                    if (evt.target.value != params.row.sort) {
                                        this.onChangeSort(params.row, evt.target.value)
                                    }
                                },
                                "on-blur": evt => {
                                    if (evt.target.value != params.row.sort) {
                                        this.onChangeSort(params.row, evt.target.value)
                                    }
                                },
                            },
                        })
                    },
                },
            ],
        }
    },

    methods: {
        onChangeSort(row, val) {
            if (val < 0) {
                return this.$Message.error({
                    content: "排序值不能负数",
                    background: true,
                })
            }

            Request.post(
                `/gateway/apps/o2o/api/terminal/category/modifyerminalCategory`,
                {
                    id: row.id,
                    sort: val,
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success({
                    content: "修改成功",
                    background: true,
                })

                row.sort = val
            })
        },
    },
}
</script>

<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}
</style>
